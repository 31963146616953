<template>
  <div class="solutionInside">
    <div class="banner">
      <img :src="banner" alt="" />
    </div>
    <div class="program_advantage">
      <div class="title">方案优势</div>
      <ul class="exhibition">
        <li v-for="item in advantageList" :key="item.title">
          <div v-if="item.sort % 2 === 0" class="exhibition_content">
            <div>
              <img :src="item.image" alt="" />
            </div>
            <div class="exhibition_text">
              <span class="exhibition_title">{{ item.title }}</span>
              <span class="text">{{ item.describes }}</span>
            </div>
          </div>
          <div v-else class="exhibition_content">
            <div class="exhibition_text">
              <span class="exhibition_title">{{ item.title }}</span>
              <span class="text">{{ item.describes }}</span>
            </div>
            <div>
              <img :src="item.image" alt="" />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="case">
      <div class="tilte">标杆案例</div>
      <div
        class="case_right"
        v-for="item in caseList"
        :key="item.solutionCaseId"
      >
        <div class="text">
          <p>{{ item.caseTitle }}</p>
          <p>
            {{ item.describes }}
          </p>
        </div>
        <div class="img">
          <img :src="item.image" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { solutionDetails } from "@/api/web";
export default {
  name: "SolutionInside",
  data() {
    return {
      advantageList: [],
      banner: null,
      caseList: [],
    };
  },
  created() {
    this.getSolutionDetails();
  },
  methods: {
    async getSolutionDetails() {
      const { data } = await solutionDetails({
        solutionId: this.$route.params.solutionId,
      });
      this.banner = data.imageTotal;
      this.advantageList = data.advantages;
      this.caseList = data.case;
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  img {
    // margin-top: -65px;
  }
}
.program_advantage {
  margin-top: 48px;
  width: 1460px;
  margin: 0 auto;
  .title {
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 51px;
    width: 144px;
    font-size: 36px;
    font-family: Microsoft YaHei;
    color: #181818;
  }
  .exhibition {
    li {
      border-bottom: 1px solid #d1d1d1;
      .exhibition_content {
        display: flex;
        justify-content: space-between;
        padding-left: 79px;
        box-sizing: border-box;
        div {
          width: 50%;
        }
        img {
          height: 100%;
        }
        .exhibition_text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;

          span {
            color: #181818;
          }
          .exhibition_title {
            font-size: 28px;
            line-height: 46px;
          }
          .text {
            font-size: 21px;
          }
        }
      }
    }
  }
}
.case {
  width: 1460px;
  margin: 0 auto;
  margin-bottom: 85px;
  .tilte {
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 50px;
    width: 144px;
    font-size: 36px;
    color: #181818;
  }
  .case_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 15px #aba9a9;
    .text {
      width: 590px;
      margin-left: 90px;
      p {
        color: #181818;
        line-height: 36px;
        &:first-of-type {
          font-size: 28px;
        }
        &:last-of-type {
          margin-top: 5px;
          font-size: 21px;
        }
      }
    }
    .img {
      height: 394px;
      img {
        height: 100%;
      }
    }
  }
}
</style>
